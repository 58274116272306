* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
}
main {
  padding: 10px;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: rgb(0, 7, 61);
  color: white;
  height: 100vh;
  overflow-y: auto;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  width: 25px;
  position: fixed;
  left: 10px;
  top: 18px;
}
.bars-open {
  width: 25px;
}
.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.active {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  overflow-y: auto;
  z-index: 1000;
  /* transition: width 0.5s ease-in-out; Transition for smooth opening/closing */
}

.sidebar.open {
  width: 250px; /* Ensure width is applied when open */
}

.main-container > main {
  position: relative;
}
.scroll-bar {
  overflow: scroll;
  height: auto;
}
::-webkit-scrollbar {
  width: 0px;
}

@media screen and (max-width: 768px) {
  .bars {
    width: 25px;
    font-size: 20px;
    position: fixed;
    margin: 10px;
    z-index: 100;
  }
}
.main{
  margin-left: 16%;
}
.input-searchfield{
  background-color: #EEEEFF;
  width: 320px;
}
.alignline{
  display: inline;
}
.serchicon{
  transform: 'translateY(-50%)'
}
@media only screen and (min-width: 868px) {
  .main{
    margin-left: 265px;
  }
  .serchicon{
    position: absolute;
    top: 50px;
    left: 290px;
  }
}
@media only screen and (max-width: 868px) {
  .main{
    margin-left: 265px;
  }
  .alignline{
    display: inline-block;
    margin-top: -35px;
    margin-left: 50px;
  }
  .serchicon{
    position: absolute;
    top: 50px;
    left: 290px;
  }
}

@media only screen and (max-width: 600px) {
  .main{
      margin-left: 0px;
  }
  .input-searchfield{
    width: 315px;
  }
  .alignline{
    display: inline-block;
    margin-top: -35px;
    margin-left: 50px;
  }
  .serchicon{
    position: absolute;
    top: 50px;
    left: 290px;
  }
}
