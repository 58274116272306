.receipt {
    width: 210mm;
    min-height: 297mm; 
    margin: auto; 
    padding: 20mm; 
    box-shadow: none; 
    background: white; 
    border: 1px solid blue;
  }
  
  /* Ensure the content uses full width */
  .frame-parent, .rectangle-parent, .main-layout-parent {
    width: 100%;
    box-sizing: border-box; /* Include padding and border in width */
  }
  
  /* Reset margins to use the full width */
  .m-0 {
    margin: 0;
  }
  input {
    background-color: transparent;
    border: none; /* Removes all borders */
    border-bottom: 1px solid gray; /* Bottom border only */
    outline: none; /* Removes the default focus outline */
    padding:0px
  }
  
  input:focus {
    border: 1px solid gray; /* All borders visible on focus */
  }
  